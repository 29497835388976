<template>
  <div class="relative mt-1">
    <div class="mx-auto max-w-7xl">
      <div class="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
        <svg
          class="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          aria-hidden="true"
        >
          <polygon points="0,0 90,0 50,100 0,100" />
        </svg>

        <div class="relative px-6 py-32 sm:py-40 lg:px-8 lg:py-10 lg:pr-0">
          <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
            <div
              class="hidden sm:mb-10 sm:flex"
              style="width: 50%; margin-left: auto; margin-right: auto"
            >
              <div
                class="mt-0 rounded-full px-3 py-1 text-sm leading-6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20"
              >
                耻辱无法忘记，盛怒不应熄灭。<a
                  href="https://baike.baidu.com/item/%E4%BA%BA%E6%B0%91%E8%8B%B1%E9%9B%84%E6%B0%B8%E5%9E%82%E4%B8%8D%E6%9C%BD/4002238"
                  class="whitespace-nowrap font-semibold text-indigo-600"
                  ><span
                    class="inset-0"
                    aria-hidden="true"
                  />人民英雄永垂不朽<span aria-hidden="true">&rarr;</span></a
                >
              </div>
            </div>
            <h1
              class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl"
            >
              为中华之崛起而读书
            </h1>
            <p class="mt-6 text-lg leading-8 text-gray-600">
              三年以来，在人民解放战争和人民革命中牺牲的人民英雄们永垂不朽！<br />三十年以来，在人民解放战争和人民革命中牺牲的人民英雄们永垂不朽！<br />由此上溯到1840年，从那时起，为了反对内外敌人，争取民族独立和人民自由幸福，在历次斗争中牺牲的人民英雄们永垂不朽！
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <img
        class="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
        src="../assets/HomeViewImages/HomeNanJing.png"
        alt=""
      />
    </div>
  </div>

  <div class="px-6 py-32 lg:px-8">
    <div class="mx-auto max-w-4xl text-base leading-7 text-gray-700">
      <h1
        class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
      >
        SunSeaLucky的个人博客
      </h1>
      <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">
        欢迎来到这里
      </h2>
      <div class="mt-10">
        <p>
          很早之前，我就有开发个人博客的想法，但奈何学业繁忙，实在没有时间，近来几天终于有时间。我一直觉得，拥有自己的个人博客是作为一名计算机技术爱好者的极大浪漫——尤其是当你将你的生活以你爱好的方式呈现时。你一定会好奇，这种“浪漫”有何作用？她（是的，我愿意称之为“她”）提供了一种与被世界关注与理解的方式，人人皆需存在感，也希望为世界做出自己的贡献。不过现在，我要告诉你（这也许是你最关心的事情，也是我最关心的事情），你能从这里获得什么。
        </p>
        <ul role="list" class="mt-8 space-y-8 text-gray-600">
          <li class="gap-x-3">
            <span
              ><strong class="font-semibold text-gray-900">资源</strong>
              资源板块提供了对大学生活的、尽可能多的资源支持，目前敲定制作的是数学建模工具包。</span
            >
          </li>
          <li class="gap-x-3">
            <span
              ><strong class="font-semibold text-gray-900">指南</strong>
              参考了CS自学指南、上海交大生存手册，告诉你如何更好的在新疆大学生存。</span
            >
          </li>
          <li class="gap-x-3">
            <span
              ><strong class="font-semibold text-gray-900">了解我</strong>
              了解一个有趣（但愿如此）的 灵魂。
            </span>
          </li>
        </ul>
        <p class="mt-8">
          这类事情有很多人在做，但我不希望我的大学没有这样的人，所以我想试试。
        </p>
        <h2 class="mt-16 text-2xl font-bold tracking-tight text-gray-900">
          追求真理、美好与正义的脚步永不停歇
        </h2>
        <p class="mt-6">
          我算是一个历史小白，初中时历史成绩就很差。对于初学历史的印象，还停留在初中历史课本第一页的元谋人。这使得我一旦想起这个元谋人，就失去了对于打开历史书的兴趣。但我仍然尝试读过《史记》这样的书，尽管因为在尝试了三天之后就因为文言文的晦涩难懂而宣告失败了，但是里面有些能看懂的故事还是深深吸引了我。这段时间，学校的期末考试要考近代史，在我鼓起勇气再次翻开历史书时，它却给了我不一样的震撼。
        </p>

        <p class="mt-6">
          看完这本厚厚的近代史后，我第一反应是自责——为什么这么晚才了解到中国的近代史？我之前听过无数次周恩来的“为中华之崛起而读书”，心中毫无波澜；也看到过网上有人说，“中国近代史就是一部血泪史与屈辱史”，也没有什么反应。究其原因，还是心智尚不成熟，思想没有达到境界。我曾想过无数次应该为自己的博客首页放什么好，现在，我怀着深深的悲痛，把“为中华之崛起而读书”放在最为显眼的位置。
        </p>

        <p class="mt-6">
          或需中华之崛起并不需要我来读书，并不需要我来努力，世界少了我照常运转，中华没了我依然可以复兴、可以强大。<strong
            >但作为一名华夏子孙，对于屈辱历史的悲痛使得我难以袖手旁观，付出你的努力吧，哪怕只能加速一点点崛起的进程。</strong
          >
        </p>
      </div>

      <div class="mt-16">
        <h2 class="text-2xl font-bold tracking-tight text-gray-900">
          这个博客到底要展现什么
        </h2>
        <p class="mt-6">
          无论怎么说，本站终究是一个个人博客。我希望给你展示的是计算机的奇妙、世界的趣味，传递给你积极向上、快乐生活的态度。如果你觉得打开本站是一件快乐且有意义的事情的话，那本站的目标已经达到了。
        </p>
        <figure class="mt-10 border-l border-indigo-600 pl-9">
          <blockquote class="font-semibold text-gray-900">
            <p>“成功只有一个 —— 按照自己的方式，去度过人生。”</p>
          </blockquote>
          <figcaption class="mt-6 gap-x-4">
            <div class="text-sm text-right">
              <strong class="font-semibold text-gray-900">当年明月</strong> –
              《明朝哪些事儿》
            </div>
          </figcaption>
        </figure>
        <figure class="mt-16">
          <img
            class="aspect-video rounded-xl bg-gray-50 object-cover"
            src="../assets/HomeViewImages/NoMatterHowWayLong.jpg"
            alt=""
          />
          <figcaption class="mt-4 flex gap-x-2 text-sm leading-6 text-gray-500">
            <InformationCircleIcon
              class="mt-0.5 h-5 w-5 flex-none text-gray-300"
              aria-hidden="true"
            />
            乌鲁木齐市地窝堡机场T2某廊桥
          </figcaption>
        </figure>
        <p class="mt-8">
          我想展示一些有意思的图片，可能图文没有什么关联，十分抱歉 。
        </p>
      </div>
    </div>
  </div>

  <footer class="bg-white mb-10">
    <p class="text-center text-xs leading-5 text-gray-500">
      &copy; 2024 SunSeaLucky 版权所有
      <br />
      <a
        href="https://beian.miit.gov.cn/"
        class="whitespace-nowrap font-semibold text-indigo-600"
        ><span
          class="inset-0"
          aria-hidden="true" />备案号：豫ICP备2024046397号<span
          aria-hidden="true"
        ></span
      ></a>
    </p>
  </footer>
</template>

<script setup>
import { ref } from "vue";
import { Dialog, DialogPanel } from "@headlessui/vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/vue/20/solid";
const mobileMenuOpen = ref(false);
</script>